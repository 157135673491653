@charset "utf-8";
@import "_variables";
@import "_mixin";

/*-------------------------------
common
-------------------------------*/

.sec_contents {
  max-width: 100%;
  margin: 0 auto;
}

.sec_contents_inner {
  max-width: 1000px;
  margin: 0 auto 5em auto;
}

.sec_ttl {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1em;
  position: relative;
  z-index: 1;

  @media #{$sp} {
    font-size: 1.7rem;
  }

  .ttl_img {
    margin-left: 1em;
    position: relative;
    z-index: -1;
  }
}

.sec_fx {
  display: flex;
}


/*--------------------------------------
  header
---------------------------------------*/

#container {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  height: 80px;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #fff;

  @media #{$sp} {
    height: 70px;
  }
}

.header_contents {
  width: 100%;
  margin: 0 auto;
  position: relative;

  &_inner {
    max-width: 1340px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$pc_b} {
      max-width: 95%;
    }

    @media #{$pc_s} {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.h_logo {
  display: flex;
  align-items: center;
  margin-left: .5em;

  @media #{$pc_m} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media #{$pc_s} {
    margin-top: 1em;
  }

  @media #{$sp} {
    flex-direction: column;
    align-items: flex-start
  }

  &_txt {
    display: block;
    @include fz(14);
    font-weight: bold;
    margin: 1em 0 0 .5em;

    @media #{$pc_m} {
      margin: 0;
    }

    @media #{$sp} {
      @include fz(12);
      margin: 0 0 0 .5em;
    }
  }
}

.header_nav_contents {
  @media #{$pc_s} {
    display: none;
    width: 100%;
    height: calc(100vh - 80px);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    background: $clr_org;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 9;

    @media #{$sp} {
      height: calc(100vh - 70px);
      top: 70px;
    }

    li {
      opacity: 0;
      transform: translateX(200px);
      transition: transform .6s ease, opacity .2s ease;

      &:nth-child(2) {
        transition-delay: .15s;
      }

      &:nth-child(3) {
        transition-delay: .3s;
      }

      &:nth-child(4) {
        transition-delay: .45s;
      }

      &:nth-child(5) {
        transition-delay: .65s;
      }

      &:nth-child(6) {
        transition-delay: .85s;
      }
    }
  }

  .header_nav_inner {
    @include fx_c_js;

    @media #{$pc_s} {
      flex-direction: column;
      align-items: flex-start;
      padding: 4em;
    }

    @media #{$sp} {
      padding: 2em 1em 5em !important;
    }

    li {
      height: 80px;
      @include fx_ac;
      justify-content: center;

      @media #{$pc_s} {
        width: 100%;
        height: auto;
        display: block;
        margin-right: 0;
        margin-bottom: 1em;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
  }

  .h_nav {
    display: block;
    @include fz(14);
    font-weight: bold;
    margin-right: 15px;
    position: relative;

    @media #{$pc_s} {
      @include fz(16);
      color: #fff;
      margin-bottom: .5em;
    }

    &:hover {
      color: $clr-org;
    }
  }
}

.h_nav_li {
  position: relative;
  height: 100%;

  .h_nav_sp {
    @media #{$pc_s} {
      display: block;
      pointer-events: none;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 5%;
        width: 6px;
        height: 6px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .nav_ttl {
    @include fz(20);
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
    margin-bottom: 20px;

    @media #{$pc_s} {
      display: none;
    }
  }

  .nav_area {
    display: none;
    width: 100%;
    background: #ff9827;
    position: fixed;
    top: 80px;
    left: 0;

    @media #{$pc_s} {
      position: relative;
      height: auto;
      top: 0;
      background: none;
    }

    &_inner {
      max-width: 1000px;
      margin: 0 auto;
      padding: 40px 0;

      @media #{$pc_s} {
        flex-direction: column;
        padding: 1em 0 0 0;
      }
    }

    &_item {
      display: flex;

      @media #{$pc_s} {
        flex-wrap: wrap;
        margin-left: 1em;
      }

      @media #{$sp} {
        flex-direction: column;
        margin-left: 0 !important;
      }

      ul {
        width: calc(100% / 3);

        @media #{$pc_s} {
          width: 50%;
          margin-bottom: 2em;
        }

        @media #{$sp} {
          width: 100% !important;
          margin: 0 0 1.5em 1em !important;
        }
      }

      &_entry {
        display: flex;
      }
    }

    &_ttl {
      @include fz(18);
      color: #fff;
      line-height: 1.7em;
      font-weight: bold;

      @media #{$pc_s} {
        @include fz(16);
      }
    }

    li {
      flex: none;
      justify-content: flex-start;
      height: auto;
      margin-bottom: 10px;
      position: relative;

      a {
        @include fz(18);
        color: #fff;

        @media #{$tab_s} {
          @include fz(16);
        }

        &:hover {
          opacity: .7;
        }
      }
    }

    .h_nav_s {
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: -20px;
        margin-top: -2px;
        width: 6px;
        height: 6px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    .h_nav_en {
      width: 185px;
      padding: 15px 0;
      border: 1px solid #fff;
      text-align: center;
      margin-right: 15px;

      &:hover {
        color: $clr-org;
        background: #fff;
      }
    }
  }
}

.nav_active {
  overflow: hidden;

  .header_contents_inner {
    height: auto;
  }

  .header_nav_contents {
    visibility: visible;
    opacity: 1;

    li {
      opacity: 1;
      transform: translateX(0);
      transition: transform 1s ease, opacity .9s ease;
    }
  }
}

.h_nav_link {
  width: 100px;
  height: 40px;
  @include fx_jc_cc;
  text-align: center;
  @include fz(14);
  font-weight: bold;
  color: $clr-grn;
  border: 1px solid $clr-grn;

  &:hover {
    background: $clr-grn;
    color: #fff;
  }

  @media #{$pc_s} {
    //display: none;
    //entryをSPメニューに表示
    width: 200px;
    margin: auto;
    color: #FFF;
    background-color: $clr-grn;
  }
}

.drawer_nav_wrapper,
.menu_trigger {
  display: none;
}

@media #{$pc_s} {
  .drawer_nav_wrapper {
    @include fx_w_js;
    justify-content: flex-end;
    position: fixed;
    top: 18px;
    right: 1em;
    z-index: 999;
    box-sizing: border-box;

    @media #{$tab_s} {
      right: .5em;
    }
  }

  .menu_trigger {
    display: inline-block;
    position: absolute;
    top: 1.3em;
    right: 1em;
    width: 35px;
    height: 25px;
    vertical-align: middle;
    cursor: pointer;

    @media #{$sp} {
      top: 1.5em;
    }

    span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 2rem;
      height: 3px;
      background: $clr-org;
      transition: all .5s;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 12px;
      }

      &:nth-of-type(3) {
        top: 25px;
      }
    }
  }

  .menu_trigger.active span {
    z-index: 3;

    &:nth-of-type(1) {
      transform: translateY(12px) rotate(-45deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: translateY(-13px) rotate(45deg);
    }

    &:nth-of-type(4) {
      opacity: 0;
    }
  }
}


/*-------------------------------
contents
-------------------------------*/

#contents {
  max-width: 100%;
  margin: 80px auto 0 auto;
  background: #E1ECFF;

  @media #{$sp} {
    margin: 70px auto 0 auto;
  }
}

.contents_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 1em;

  @media #{$tab} {
    max-width: 90%;
  }

  @media #{$sp} {
    max-width: 90%;
  }
}

.kv_wrap {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 1em;

  @media #{$tab} {
    max-width: 90%;
  }
}

.kv_area {
  width: 100%;
  height: auto;
  background: url("../images/kv_img.png") center bottom no-repeat;
  padding-bottom: 74.5%;
  margin-bottom: 127px;

  @media #{$tab} {
    width: 90%;
    margin: 0 auto 6em auto;
    background-size: contain;
    padding-bottom: 72%;
  }

  @media #{$sp} {
    width: 100%;
    background-size: contain;
    padding-bottom: 80%;
    margin-bottom: 3em;
  }

  .kv_area_inner {
    padding-top: 70px;

    @media #{$tab} {
      padding-top: 40px;
    }

    @media #{$sp} {
      padding-top: 35px;
    }
  }
}

.kv_cat {
  @media #{$tab} {
    width: 60%;
  }

  @media #{$sp} {
    width: 80%;
  }
}

.kv_txt_area {
  width: 100%;
  padding: 4em 0;
  text-align: center;
  margin-bottom: 75px;
  background: #fff;
  box-shadow: 0 0 8px rgba(153, 153, 153, 0.8);
  border-radius: 0.5em;
  position: relative;

  &:before {
    content: "";
    display: block;
    background: url("../images/t_me_ttl.png") center no-repeat;
    width: 100%;
    height: auto;
    padding-bottom: 20%;
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;

    @media #{$tab} {
      background-size: contain;
    }

    @media #{$sp} {
      background-size: contain;
      padding-bottom: 23%;
    }
  }

  .kv_ttl {
    font-size: 1.5rem;
    margin-bottom: 0.5em;

    @media #{$sp} {
      font-size: 1.3rem;
    }
  }

  .kv_txt {
    line-height: 2em;
    position: relative;

    @media #{$sp} {
      padding: 0 1.5em;
      line-height: 1.8em;
      text-align: left;
      font-size: 0.9rem;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 152px;
      background: url("../images/m_img01.png")left no-repeat;
      animation: float 3s linear infinite;
      transform-origin: 50% 50%;
      margin: 1rem 0 !important;
      position: absolute;
      left: 3em;
      top: -8em;

      @media #{$tab} {
        background-size: contain;
        height: auto;
        padding-bottom: 19%;
        left: 1em;
      }

      @media #{$sp} {
        background-size: contain;
        height: auto;
        padding-bottom: 25%;
        left: 0.5em;
        top: -8em;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 112px;
      background: url("../images/m_img02.png")right no-repeat;
      transform-origin: 50% 50%;
      margin: 1rem 0 !important;
      position: absolute;
      right: 3em;
      bottom: -3em;

      @media #{$tab} {
        background-size: contain;
        height: auto;
        padding-bottom: 13%;
        right: 1em;
      }

      @media #{$sp} {
        background-size: contain;
        height: auto;
        padding-bottom: 20%;
        right: 0.5em;
        bottom: -5em;
      }
    }
  }
}


/*-------------------------------
  TOPページ 新着情報
-------------------------------*/

.tabs {
  width: 800px;
  margin: 0 auto 2em auto;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media #{$tab} {
    width: 100%;
  }

  @media #{$sp} {
    width: 100%;
  }
}

.tab_item {
  width: calc(99.9%/3);
  height: 55px;
  background-color: #b6b6b6;
  line-height: 55px;
  text-align: center;
  display: block;
  float: left;
  color: #fff;
  font-weight: 400;
  border-right: 2px solid #dbdbdb;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:nth-last-of-type(1) {
    border-right: none;
  }

  @media #{$sp} {
    font-size: 0.7rem;
    line-height: 1.8em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media #{$sp_s} {
    font-size: 0.6rem;
  }
}

input[name="tab_item"] {
  display: none;
}

.tab_content {
  display: none;
  clear: both;
  overflow: hidden;
}

#tab1:checked~#tab1_content,
#tab2:checked~#tab2_content,
#tab3:checked~#tab3_content {
  display: block;
}

#tab1:checked+.tab_item {
  background: $clr-org;

  &::after {
    content: "";
    display: block;
    margin-left: -3%;
    position: absolute;
    left: 50%;
    border: 7px solid transparent;
    border-top: 10px solid $clr-org;

    @media #{$sp} {
      bottom: -1.2em;
      margin-left: -6%;
    }
  }
}

#tab2:checked+.tab_item {
  background: #399F4A;

  &::after {
    content: "";
    display: block;
    margin-left: -3%;
    position: absolute;
    left: 50%;
    border: 7px solid transparent;
    border-top: 10px solid #399F4A;

    @media #{$sp} {
      bottom: -1.2em;
      margin-left: -6%;
    }
  }
}

#tab3:checked+.tab_item {
  background: #2995CC;

  &::after {
    content: "";
    display: block;
    margin-left: -3%;
    position: absolute;
    left: 50%;
    border: 7px solid transparent;
    border-top: 10px solid #2995CC;

    @media #{$sp} {
      bottom: -1.2em;
      margin-left: -6%;
    }
  }
}

.info_item {
  padding: 2em 0;
  border-bottom: 1px solid #ccc;

  @media #{$sp} {
    padding: 2em 1em;
  }

  /*&:nth-last-of-type(1) {
	border-bottom:none;	
	}*/
  .info_item_inner,
  .info_item_inner a {
    width: 590px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media #{$sp} {
      width: 100%;
      display: block;
    }
  }

  .top_info_date {
    border-right: 1px solid #000;
    padding-right: 15px;

    @media #{$sp} {
      border-right: none;
    }
  }

  .top_info_txt {
    padding-left: 15px;

    @media #{$sp} {
      padding-left: 0;
    }
  }
}

.more_btn {
  display: block;
  width: 180px;
  height: 50px;
  line-height: 48px;
  margin: 1em auto;
  border: 1px solid $clr-org;
  border-radius: 2em;
  color: $clr-org;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  @media #{$sp} {
    width: 75%;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 20%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    border-right: 1.5px solid $clr-org;
    border-bottom: 1.5px solid $clr-org;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &:hover {
    background: $clr-org;
    color: #fff;
  }

  &:hover::after {
    border-right: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
  }
}


/*-------------------------------
社員を知る
-------------------------------*/

.top_person_item {
  background: #fff;
  padding: 3em 1.5em;
  border-radius: 0.5em;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative
}

.top_person_item:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 8px;
  background: #FF9900;
  border-top-left-radius: 0.5em
}

.top_person_item .sec_ttl span img {
  position: relative;
  z-index: -1
}

.top_person_item #slick {
  margin-bottom: 4em
}

.top_person_item .slick_img {
  position: relative
}

.top_person_item .slick_img img {
  width: 100%
}

.top_person_item .slick_img:hover,
.top_person_item .slick_img a:hover {
  opacity: 1
}

.top_person_item .slick_txt {
  position: absolute;
  bottom: 0.5em;
  left: 1em;
  font-size: 0.8rem;
  color: #000;
  font-weight: bold
}

.top_person_item .slick_txt span {
  font-size: 1rem
}

.top_person_item .slick_txt02 {
  color: #fff
}

.top_person_item button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.top_person_item button:hover {
  cursor: pointer
}

.top_person_item .slide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 1
}

.top_person_item .prev-arrow {
  left: 0;
  width: 40px;
  height: 40px;
  background: url("../images/arrow01.svg") no-repeat
}

@media only screen and (max-width:640px) {
  .top_person_item .prev-arrow {
    left: -0.5em
  }
}

.top_person_item .next-arrow {
  right: 0;
  width: 40px;
  height: 40px;
  background: url("../images/arrow02.svg") no-repeat
}

@media only screen and (max-width:640px) {
  .top_person_item .next-arrow {
    right: -0.5em
  }
}

.top_person_item .slick-slide {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9)
}

.top_person_item .slick-slide:hover {
  opacity: 1;
  border-radius: 10px;
  cursor: pointer
}

.top_person_item .slick-current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.top_person_item .slick-current:hover {
  background: #FF8000;
  opacity: 1;
  border-radius: 10px;
  cursor: pointer
}

@media only screen and (max-width:640px) {
  .top_person_item .slick-current {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9)
  }
}

.top_person_item .slick-dots li button:before {
  font-size: 0;
  content: "";
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #999;
  opacity: 1
}

.top_person_item .slick-dots {
  bottom: -40px
}

.top_person_item .slick-dots li {
  margin: 0 1px
}

.top_person_item .slick-dots li.slick-active button:before {
  background: #FF8000;
  opacity: 0.75
}


/*-------------------------------
リンクエリア　
-------------------------------*/

.top_link_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media #{$sp} {
    flex-direction: column;
  }

  .top_link_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(95%/3);
    padding: 3em 0;
    background: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    position: relative;

    @media #{$tab} {
      flex-direction: column;
    }

    @media #{$sp} {
      width: 100%;
      padding: 2em 0;
      margin-bottom: 1em;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 170px;
      height: 8px;
      background: $clr-org;
      border-top-left-radius: 0.5em;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -7px;
      width: 10px;
      height: 10px;
      border-right: 2px solid #ff8000;
      border-bottom: 2px solid #ff8000;
      transform: rotate(-45deg);
    }

    .top_link_item_img img {
      @media #{$sp} {
        width: 70%;
      }
    }

    .top_link_item_img02 {
      margin-left: 2em;

      @media #{$tab} {
        margin-left: 0;
      }
    }
  }

  .sec_ttl {
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-right: 0.5em;

    @media #{$tab} {
      margin-bottom: 0.5em;
    }

    @media #{$sp} {
      font-size: 1.3rem;
    }

    span {
      display: block;
      margin-top: -0.3em;
      z-index: -1;
      position: relative;
    }
  }
}


/*-------------------------------
学研ココファンについて
-------------------------------*/

.top_about_item {
  width: 100%;
  height: 415px;
  background: url("../images/t_about_bg.png")no-repeat;
  padding: 2em;
  border-radius: 0.5em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  position: relative;

  @media #{$tab} {
    background-position: center;
  }

  @media #{$sp} {
    padding: 1.5em;
    background-position: -30em;
  }

  .top_about_ttl {
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 4em;

    @media #{$sp} {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 3em;
    }

    .top_about_ttl_img {
      position: absolute;
      top: 35%;
      left: 40%;
      z-index: -1;

      @media #{$tab} {
        left: 35%;
      }

      @media #{$sp} {
        left: 25%;
        z-index: -1;
        width: 50%;
      }
    }
  }

  .top_about_link {
    max-width: 760px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$sp} {
      flex-direction: column;
    }
  }

  .about_link {
    width: 48%;
    height: 75px;
    line-height: 70px;
    margin-bottom: 1.5em;
    font-family: $font_tsuku;
    color: $clr-org;
    font-size: 1.6rem;
    border: 2px solid $clr-org;
    border-radius: 50px;
    background: #fff;
    text-align: center;
    position: relative;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -7px;
      width: 15px;
      height: 15px;
      border-right: 2px solid $clr-org;
      border-bottom: 2px solid $clr-org;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);

      @media #{$sp} {
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      opacity: 1;
      color: #fff;
      background: $clr-org;

      &::after {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
    }

    @media #{$sp} {
      width: 90%;
      margin: 0 auto;
      font-size: 1.1rem;
      height: 55px;
      line-height: 50px;
      margin-bottom: 1em;
    }
  }

  @media all and (-ms-high-contrast: none) {
    .about_link {
      line-height: 85px;
    }
  }
}


/*-------------------------------
  Footer
-------------------------------*/

.footer_area {
  width: 100%;
  background: #ffffff;

  @media #{$tab} {
    padding-bottom: 2em;
  }

  @media #{$sp} {
    padding-bottom: 2em;
  }

  .footer_inner {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 3em 0 2em 0;
    text-align: center;

    @media #{$pc} {
      width: 95%;
      padding: 2em 0 1em 0;
    }

    @media #{$tab} {
      width: 75%;
      padding: 2em 0 1em 0;
    }

    @media #{$sp} {
      width: 75%;
      flex-direction: column;
      padding: 3em 0 0 0;
    }
  }

  .footer_item {
    display: flex;
    justify-content: space-between;

    @media #{$tab} {
      flex-wrap: wrap;
    }

    @media #{$sp} {
      flex-direction: column;
      align-items: flex-start;
    }

    &_inner {
      display: flex;
      flex-direction: column;

      @media #{$sp} {
        margin-top: 1em;
      }
    }

    p,
    a,
    li {
      display: block;
      @include fz(14);
      text-align: left;
      line-height: 1.8;
    }
  }

  .footer_top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media #{$tab} {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .footer_logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;

    @media #{$tab} {
      margin-bottom: 0.5em;
    }

    @media #{$sp} {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1em;
    }

    &_txt {
      font-weight: bold;
      font-size: 13px;
      padding: 0 0 0 0.5em;
      display: inline-block;

      @media #{$sp} {
        display: block;
        font-size: 11px;
        padding: 0;
        line-height: 2em;
      }
    }
  }

  .footer_logo_img {
    margin-right: auto;

    @media #{$sp} {
      text-align: left;
    }
  }

  .f_nav_item_rt {
    margin-top: 28px;

    @media #{$sp} {
      margin-top: 0;
    }

    a {
      margin-left: 1em;
    }

    .f_nav_ttl_s {
      margin-bottom: 0;
    }

    .f_nav_item {
      .f_nav_ttl_s {
        margin-top: 15px;
      }
    }
  }

  .f_nav_ttl {
    color: $clr-org;
    font-weight: bold;
    text-align: left;
    margin-bottom: .2em;

    @media #{$sp} {
      margin-bottom: .5em;
    }

    &_s {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .f_nav_item {
    &_inner {
      width: calc(100% / 3);
    }
  }

  .f_icon {
    font-weight: 500;
    position: relative;

    @media #{$sp} {
      margin-bottom: 0.5em;
    }

    &:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      transform: rotate(-45deg);
      margin: -1px 0 0 2px;

      @media #{$sp} {
        width: 8px;
        height: 8px;
      }
    }
  }

  .f_nav {
    font-weight: bold;
    color: $clr-org;

    &_b {
      margin-bottom: 15px;
    }

    &_en {
      border: 1px solid $clr-org;
      color: $clr-org;
      font-weight: bold;
      padding: 15px 35px;
      margin-bottom: 15px;
    }
  }

  .f_link_area {
    @media #{$sp} {
      text-align: left;
      margin-top: .5em;
    }

    img {
      @media #{$sp} {
        width: 30%;
      }
    }
  }

  .footer_nav {
    position: relative;

    &:hover {
      &:before {
        position: absolute;
        content: " ";
        display: block;
        width: 50%;
        bottom: -10px;
        left: 50%;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;

        @media #{$sp} {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: " ";
        display: block;
        width: 50%;
        bottom: -10px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;

        @media #{$sp} {
          display: none;
        }
      }
    }

    a {
      display: block;
      color: $clr-org;
      font-weight: bold;
      margin-right: 1.5em;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: inline-block;
        width: 20px;
        height: 1px;
        background-color: $clr-org;
        transform: rotate(-60deg);

        @media #{$sp} {
          right: -22px;
        }
      }

      @media #{$tab} {
        font-size: 0.9rem;
        text-align: center;
      }

      @media #{$sp} {
        line-height: 2em;
        font-size: 0.8rem;
        padding: 0.5em 0;
        position: relative;
      }

      &:hover {
        @media #{$sp} {
          color: #fff;
        }
      }
    }

    &:nth-last-of-type(1) a:after {
      background: none;
    }
  }

  .f_btm {
    display: flex;
    justify-content: flex-end;

    @media #{$sp} {
      flex-direction: column;
    }
  }

  .f_link {
    margin-right: 0.5em;
  }

  .h_link02 {
    width: 175px;
    height: 45px;
    line-height: 40px;
    border: 1px solid $clr-org;
    color: $clr-org;
    margin-top: 0;
    margin-right: 1em;
    font-weight: bold;
    position: relative;

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      right: 3px;
      margin-top: -5px;
      width: 8px;
      height: 8px;
      border-right: 1.5px solid #ff8000;
      border-bottom: 1.5px solid #ff8000;
      transform: rotate(-45deg);
      margin-right: 8px;
    }

    @media #{$sp} {
      width: 200px;
      margin-right: 0;
      margin-bottom: 1em;
    }

    &:hover {
      opacity: 1;
      color: #fff;
      background: $clr-org;

      &:after {
        border-right: 1.5px solid #fff;
        border-bottom: 1.5px solid #fff;
      }
    }
  }

  .h_link03 {
    display: block;
    width: 175px;
    border: 1px solid $clr-grn;
    background: $clr-grn;
    height: 45px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    position: relative;

    @media #{$sp} {
      width: 200px;
    }

    &:hover {
      opacity: 1;
      color: $clr-grn;
      background: #fff;
    }
  }

  .footer_copy {
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;

    @media #{$sp} {
      font-size: 0.6rem;
    }
  }
}


/*-------------------------------
  page-top
-------------------------------*/

#page-top {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 7;

  a {
    display: block;
    background: $clr-org;
    box-shadow: 0 0 2px 2px rgba(236, 232, 232, .8);
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    position: relative;

    @media #{$sp} {
      width: 40px;
      height: 40px;
    }

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      display: block;
      border-top: solid 3px #ffffff;
      border-right: solid 3px #ffffff;
      transform: rotate(-45deg);
      position: absolute;
      top: 7px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);

      @media #{$sp} {
        width: 10px;
        height: 10px;
        top: 3px;
      }
    }
  }
}