/*-------------------------------
  flex
-------------------------------*/

@mixin fx_ac {
  display: flex;
  align-items: center;
}

@mixin fx_w_js {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin fx_js {
  display: flex;
  justify-content: space-between;
}

@mixin fx_c_js {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin fx_jc_cc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


/*-------------------------------
  font
-------------------------------*/

@mixin fz($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}

@mixin txt_in {
  text-indent: -1em;
  margin-left: 1em;
}

@mixin l_height($line-height) {
  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
  }
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}

@mixin f_mplus{
  font-family: $font_zenmaru;
  color: $clr-org;
  font-weight: 500;
  line-height: 1.3;
}
/*------------------------------
  position
-------------------------------*/

@mixin ps_50_50 {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
