//--- MediaQuery ---//
$pc_m:"only screen and (min-width: 1050px)and(max-width:1340px)";
$pc_b:"only screen and (max-width:1340px)";
$pc_s:"only screen and (max-width: 1050px)";
$pc:"only screen and (min-width: 1024px)";
$tab:"only screen and  (min-width:641px) and (max-width:1024px)";
$tab_s:"only screen and (max-width:900px)";
$sp:"only screen and (max-width:640px)";
$sp_s:"only screen and (max-width: 320px)";

//--- IE ---//
$ie:"all and (-ms-high-contrast: none)";

//カラー
$clr_base: #000000;
$clr_gry: #4d4d4d;
$clr-org: #FF8000;
$clr-grn: #008e42;
$clr-yel: #ffefc9;
$clr-red: #ea2a2a;
$clr-pnk: #ffe6e6;
$clr-bgrn: #00804b;
$clr-sgrn: #e0e9cd;
$clr-sblu: #d7eaff;
$clr-blu: #3f80bb;

//font
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap');
$font_zenmaru: 'Zen Maru Gothic',
sans-serif;
$font_yumin: "游明朝",
"Yu Mincho",
YuMincho,
"Hiragino Mincho ProN",
HGS明朝E,
メイリオ,
Meiryo,
serif;
$font_yugo: "游ゴシック体",
YuGothic,
"游ゴシック Medium",
"Yu Gothic Medium",
"游ゴシック",
"Yu Gothic",
"メイリオ",
sans-serif;
$font_yugo_bold: "游ゴシック体",
YuGothic,
"游ゴシック",
"Yu Gothic",
"メイリオ",
sans-serif;
$font_tsuku: fot-tsukuardgothic-std,
sans-serif;
$font_noto: 'Noto Sans JP',
"ヒラギノ角ゴ Pro W3",
"メイリオ",
Meiryo,
"游ゴシック体",
"Yu Gothic",
YuGothic,
"ＭＳ Ｐゴシック",
sans-serif;